<template>
    <div class="ag-tag-container" :class="computedClassesContainer" :id="id" :ref="id">
        <v-chip class="ag-tag" :close="close" :class="computedClassesTag" close-icon="$clear" @click:close="onClose" @click="onClick">
            <v-icon class="ag-tag-icon" :class="iconClass" left v-if="hasIcon">
                {{ icon }}
            </v-icon>
            <slot>

            </slot>
        </v-chip>
    </div>
</template>

<script>

export default {
    name: "Tag",

    props: {
        id: {
            type: String,
            default: () => {return _.uniqueId('ag_tag_')}
        },
        small: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        iconClass: {
            type: String,
            default: ''
        },
        outlined: {
            type: Boolean,
            default: false
        },
        clickable: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        close: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hasIcon() {
            return ![undefined, null, ''].includes(this.icon);
        },
        computedClassesTag() {
            let classes = '';

            classes = this.small ? 'ag-tag-sm' : '';

            return classes;
        },
        computedClassesContainer() {
            let typeClass = '';
            if(this.disabled) {
                return 'ag-tag-disabled'
            }
            switch (this.type) {
                case 'success':
                    typeClass = 'ag-tag-success';
                    break;
                case 'warning':
                    typeClass = 'ag-tag-warning';
                    break;
                case 'error':
                    typeClass = 'ag-tag-error';
                    break;
                case 'info':
                    typeClass = 'ag-tag-info';
                    break;
                default:
                    typeClass = 'ag-tag-default';
            }
            typeClass += this.active ? ' ag-tag-active' : '';
            typeClass += this.outlined ? ' ag-tag-outlined' : '';
            typeClass += this.clickable ? ' ag-tag-clickable' : '';
            return typeClass;
        }
    },
    methods: {
        onClose() {
            this.$emit('click:close', this);
        },
        onClick(e) {
            if(this.clickable) {
                this.$emit('click', e);
            }
        }
    }

}

</script>

<style lang="scss" scoped>
@import './src/design/components/tag.scss';
</style>