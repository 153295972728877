import { render, staticRenderFns } from "./Tag.vue?vue&type=template&id=7ed2fb76&scoped=true"
import script from "./Tag.vue?vue&type=script&lang=js"
export * from "./Tag.vue?vue&type=script&lang=js"
import style0 from "./Tag.vue?vue&type=style&index=0&id=7ed2fb76&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ed2fb76",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7ed2fb76')) {
      api.createRecord('7ed2fb76', component.options)
    } else {
      api.reload('7ed2fb76', component.options)
    }
    module.hot.accept("./Tag.vue?vue&type=template&id=7ed2fb76&scoped=true", function () {
      api.rerender('7ed2fb76', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/router/views/commons/Tag.vue"
export default component.exports