var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: _vm.id,
      staticClass: "ag-tag-container",
      class: _vm.computedClassesContainer,
      attrs: { id: _vm.id },
    },
    [
      _c(
        "v-chip",
        {
          staticClass: "ag-tag",
          class: _vm.computedClassesTag,
          attrs: { close: _vm.close, "close-icon": "$clear" },
          on: { "click:close": _vm.onClose, click: _vm.onClick },
        },
        [
          _vm.hasIcon
            ? _c(
                "v-icon",
                {
                  staticClass: "ag-tag-icon",
                  class: _vm.iconClass,
                  attrs: { left: "" },
                },
                [_vm._v(" " + _vm._s(_vm.icon) + " ")]
              )
            : _vm._e(),
          _vm._t("default"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }